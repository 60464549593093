import React, { Component } from "react";
import logo from "../../logo.png";
import { Route, Switch, NavLink } from "react-router-dom";

import {
  ArchiveOrderContainer,
  EditOrderContainer,
  LogoutContainer,
} from "containers";

class OrdersContainer extends Component {
  render() {
    return (
      <div className="full-screen">
        <div className="sidebar">
          <div className="logo-tfg">
            <img src={logo} className="img-fluid" alt="TFG" />
          </div>
          <ul className="list-group">
            <li className="list-group-item">
              <div className="pb-2" style={{ display: "block", width: "100%" }}>
                Orders
              </div>
              <ul className="menu list-unstyled" style={{ display: "block" }}>
                <li className="nav-item">
                  <NavLink to="/orders/search" className="nav-link">
                    <i className="icon-puzzle"></i>
                    Search Orders
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="list-group-item">
              <NavLink to="/users/search">Users</NavLink>
            </li>
            <li className="list-group-item">
              <NavLink to="/pricing/search">Pricing</NavLink>
            </li>
            <li className="list-group-item">
              <LogoutContainer />
            </li>
          </ul>
        </div>
        <div className="content">
          <div className="top__header">
            <span className="top__header__title">Orders</span>
          </div>
          <div className="top__main">
            <Switch>
              <Route exact path="/" component={ArchiveOrderContainer} />
              <Route
                exact
                path="/orders/search"
                component={ArchiveOrderContainer}
              />
              <Route path="/orders/:orderId" component={EditOrderContainer} />
              <Route component={NoMatch} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

const NoMatch = (props) => (
  <div className="alert alert-warning">Page Not Found</div>
);

export default OrdersContainer;
