import React from "react";
import Dropzone from "react-dropzone";
const BASE_URL = process.env.REACT_APP_BASE_URL || "http://localhost:3001";

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: this.props.value,
      loading: false
    };
  }

  onDrop = files => {
    if (this.props.onDrop) {
      this.setState({ loading: true });
      this.props.onDrop(files).then(resp => {
        this.setState({ loading: false });
      });
    } else {
      this.setState({ loading: true });
      let data = new FormData();
      data.append("asset", files[0]);

      // use the file endpoint
      fetch(`${BASE_URL}/asset`, {
        method: "POST",
        body: data
      })
        .then(response => {
          return response.json();
        })
        .then(image => {
          this.setState({ loading: false });

          this.props.onChange("asset", {
            imageId: image.filename,
            imageUrl: image.url,
            type: image.type
          });

          this.props.onChange("imageType", image.imageType);
        });
    }
  };

  render() {
    const {
      value: { imageId }
    } = this.props;
    return (
      <section>
        {this.state.loading ? (
          <div className="dropzone">
            <p>Uploading</p>
          </div>
        ) : (
          <Dropzone className="dropzone" onDrop={this.onDrop.bind(this)}>
            <p>Select or drop file here</p>
          </Dropzone>
        )}

        <div>
          {imageId && (
            <aside>
              <label>Uploaded file</label>
              <ul>
                <li>{imageId} </li>
              </ul>
            </aside>
          )}
        </div>
      </section>
    );
  }
}

export default FileUpload;
