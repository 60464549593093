import moment from "moment";
import currencyFormatter from "currency-formatter";

export const pluralize = (count, single, double) => {
  if (count > 1) {
    return `${count} ${double}`;
  }

  return `${count} ${single}`;
};

const paymentTypes = {
  credit: "Credit Card",
  eft: "EFT"
};

const statusTypes = {
  cancel: "Cancelled",
  approved: "Approved",
  declined: "Declined",
  pending: "Pending"
};

export const formatDate = date => {
  return moment(date).format("YYYY/MM/DD");
};

export const formatPayment = payment => {
  return paymentTypes[payment] || "None";
};

export const formatStatus = status => {
  return statusTypes[status] || "Invalid order status";
};

export const currencyFormat = amount => {
  return currencyFormatter.format(amount, {
    code: "ZAR",
    format: "%s %v",
    symbol: "R",
    decimal: ".",
    thousand: ",",
    precision: 0
  });
};

export const getTotal = item => {
  const total = item.quantity * item.price;
  return currencyFormat(total);
};

export const formatPrice = price => {
  return price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1 ");
};
