import axios from "axios";

export const downloadImage = (id) => {
  const link = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "https://sterns.ppmio.com/api";

  axios({
    url: `${link}/product/${id}`,
    method: "GET",
    responseType: "blob",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "file.png");
    document.body.appendChild(link);
    link.click();
  });
};

export const getProductConfig = (configuration, components) => {
  return configuration.reduce((result, item) => {
    if (item.specification) {
      Object.keys(item.specification).forEach((spec) => {
        const componentInfo = components.find((f) => f.sku === spec) || {};
        if (componentInfo.type === "engrave") {
          result.push({
            ...componentInfo,
            id: item.specification[spec],
            value: item.specification[spec],
          });
        } else if (componentInfo.type) {
          result.push({
            ...componentInfo,
            name: getItemKey(
              componentInfo.items,
              item.specification[spec],
              "name"
            ),
            id: item.specification[spec],
          });
        }
      });
    } else {
      result.push(item);
    }

    return result;
  }, []);
};

export const getConfigurationOptions = (components) => {
  return components.reduce((result, comp) => {
    if (["selection", "additional"].includes(comp.type)) {
      comp.options.forEach((opt) => {
        result.push(opt);
      });
    } else {
      result.push(comp);
    }

    return result;
  }, []);
};

export const getItemKey = (items = [], id, key) => {
  const option = items.find((item) => item.id === id);
  if (option) {
    return option[key];
  }
  return id;
};
