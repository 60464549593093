import React, { Component } from "react";

import {
  UsersContainer,
  AuthenticationContainer,
  OrdersContainer,
  PricingContainer,
} from "containers";
import { LoginForm } from "components";
import { BrowserRouter as Router, Route } from "react-router-dom";

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Route
            exact
            path="/"
            component={AuthenticationContainer(OrdersContainer)}
          />
          <Route exact path="/login" component={LoginForm} />
          <Route
            path="/orders"
            component={AuthenticationContainer(OrdersContainer)}
          />
          <Route
            path="/users"
            component={AuthenticationContainer(UsersContainer)}
          />
          <Route
            path="/pricing"
            component={AuthenticationContainer(PricingContainer)}
          />
        </div>
      </Router>
    );
  }
}

export default App;
