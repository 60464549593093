import React from "react";
import { PricingForm, pricingFields } from "components";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { Alert } from "@lucio-erasmus/tfgj-components";
import { compose } from "recompose";

class CreatePriceContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      input: {},
      sent: false,
      error: null,
      errors: {},
    };
  }

  onChange = (n, v) => {
    this.setState({
      input: {
        ...this.state.input,
        [n]: v,
      },
    });
  };

  handleSubmit = (e) => {
    const hasErrors = this.validateForm(this.state.input);

    if (hasErrors) {
      return null;
    }
    this.setState({ sent: false, loading: true, error: null });
    this.props
      .addPrice({
        variables: {
          input: this.state.input,
        },
      })
      .then(({ data }) => {
        this.setState({ loading: false, sent: true, input: {} });
      })
      .catch((error) => {
        this.setState({ loading: false, error: error.message });
      });
  };

  validateForm(input) {
    let errors = {};
    pricingFields().forEach((f) => {
      if (f.required && !input[f.name]) {
        errors[f.name] = `Please select`;
      }
    });
    if (Object.keys(errors).length) {
      this.setState({ errors: errors });
      return true;
    } else {
      this.setState({ errors: {} });
      return false;
    }
  }

  render() {
    const { loading, sent, error, errors, input } = this.state;
    if (loading) {
      return <p>Loading</p>;
    }

    return (
      <div>
        <div className="col-md-7">
          <PricingForm
            form={input}
            onChange={this.onChange}
            onSubmit={this.handleSubmit}
            {...this.props}
            errors={errors}
          />
        </div>
        <div className="mt-3">
          {sent && <Alert status="success">Price created</Alert>}
          {error && <span className="text-danger">{error}</span>}
        </div>
      </div>
    );
  }
}

const addPriceMutation = gql`
  mutation addPrice($input: PriceInput) {
    addPrice(input: $input) {
      sku
    }
  }
`;

export default compose(
  graphql(addPriceMutation, {
    name: "addPrice",
  })
)(CreatePriceContainer);
