import React from "react";
import logo from "../../logo.png";
import { Route, Switch, NavLink } from "react-router-dom";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import {
  ArchivePriceContainer,
  EditPriceContainer,
  LogoutContainer,
  CreatePriceContainer,
} from "containers";

class PricingContainer extends React.Component {
  render() {
    const {
      userQuery: { isPricingAdmin, loading },
    } = this.props;

    if (loading) {
      return <div>Loading...</div>;
    }

    return (
      <div className="full-screen">
        <div className="sidebar">
          <div className="logo-tfg">
            <img src={logo} className="img-fluid" alt="TFG" />
          </div>
          <ul className="list-group">
            <li className="list-group-item">
              <NavLink to="/">Orders</NavLink>
            </li>
            <li className="list-group-item">
              <NavLink to="/users/search" activeStyle="active">
                Users
              </NavLink>
            </li>
            <li className="list-group-item">
              <div className="pb-2" style={{ display: "block", width: "100%" }}>
                Pricing
              </div>
              <ul className="menu list-unstyled" style={{ display: "block" }}>
                <li className="nav-item">
                  <NavLink to="/pricing/search" className="nav-link">
                    <i className="icon-puzzle"></i>
                    Search
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/pricing/create" className="nav-link">
                    <i className="icon-puzzle"></i>
                    Create Price
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="list-group-item">
              <LogoutContainer />
            </li>
          </ul>
        </div>

        <div className="content">
          <div className="top__header">
            <span className="top__header__title">Pricing</span>
          </div>
          {isPricingAdmin ? (
            <div className="top__main">
              <Switch>
                <Route
                  exact
                  path="/pricing/search"
                  component={ArchivePriceContainer}
                />
                <Route
                  path="/pricing/create"
                  component={CreatePriceContainer}
                />
                <Route
                  path="/pricing/:priceId"
                  component={EditPriceContainer}
                />

                <Route component={NoMatch} />
              </Switch>
            </div>
          ) : (
            <div className="top__main">
              <div>Unauthorized</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const NoMatch = (props) => (
  <div className="alert alert-warning">Page Not Found</div>
);

const USER_QUERY = gql`
  {
    isPricingAdmin
  }
`;

export default graphql(USER_QUERY, { name: "userQuery" })(PricingContainer);
