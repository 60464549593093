import React from "react";
import logo from "../../logo.png";

import { graphql } from "react-apollo";
import gql from "graphql-tag";
import _ from "lodash";
import { Redirect } from "react-router-dom";
import { Alert, Button } from "@lucio-erasmus/tfgj-components";

const LoginForm = ({ errors, onChange, onSubmit, username, password }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-lg-4 m-auto">
          <div className="account-wall">
            <img
              src={logo}
              className="img-fluid"
              width="220"
              height="68"
              alt="TFG"
            />
            {errors &&
              errors.map((e, i) => (
                <Alert status="error" key={i}>
                  {e}
                </Alert>
              ))}

            <form className="form-signin mt-3 mb-3">
              <div className="form-group">
                <input
                  type="text"
                  name="username"
                  placeholder="Email"
                  onChange={e => onChange(e.target.name, e.target.value)}
                  className="form-control mb-1"
                  value={username || ""}
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={e => onChange(e.target.name, e.target.value)}
                  className="form-control"
                  value={password || ""}
                />
              </div>
              <Button onClick={e => onSubmit(e)}>Sign In</Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

class LoginContainer extends React.Component {
  state = {
    redirectToReferrer: false
  };
  onChange = (n, v) => {
    this.setState({
      [n]: v
    });
  };
  onSubmit = e => {
    e.preventDefault();

    this.setState({ error: null });
    this.props
      .userLogin({ variables: this.state })
      .then(response => {
        const siteId =
          window.location.hostname === "personalise-admin.sterns.co.za" ||
          window.location.hostname === "sterns-admin.ppmio.com"
            ? "Sterns"
            : "ASJ";
        const { token } = response.data.login;
        localStorage.setItem("token", token);
        localStorage.setItem("siteId", siteId);
        this.setState({ redirectToReferrer: true });
      })
      .catch(err => {
        console.log(err.graphQLErrors);
        let errors = _.isEmpty(err.graphQLErrors)
          ? ["Unexpected error."]
          : ["Incorrect username / password"];
        this.setState({ errors });
      });
  };

  render() {
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    return (
      <LoginForm
        onSubmit={this.onSubmit}
        onChange={this.onChange}
        {...this.state}
      />
    );
  }
}

const USER_LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
    }
  }
`;

export default graphql(USER_LOGIN, { name: "userLogin" })(LoginContainer);
