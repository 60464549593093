import React from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import "react-table/react-table.css";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import { ButtonText, Alert, Copy } from "@lucio-erasmus/tfgj-components";
import { compose } from "recompose";
import { PricingForm } from "../../components/Pricing/PricingForm";

class ArchivePriceContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      input: {},
      sent: false,
      confirm: false,
      id: null,
    };
  }

  onChange = (n, v) => {
    this.setState({
      input: {
        ...this.state.input,
        [n]: v,
      },
    });
  };

  handleSubmit = (e) => {
    this.props.getPrices.refetch({
      input: {
        ...this.state.input,
      },
    });
  };

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const columns = [
      {
        id: "product",
        Header: "Product",
        accessor: (d) => {
          return d.product ? d.product : "CYOR";
        },
      },
      {
        id: "style",
        Header: "Style",
        accessor: (d) => d.style,
      },
      {
        id: "shape",
        Header: "Shape",
        accessor: (d) => d.shape,
      },
      {
        id: "metal",
        Header: "Metal",
        accessor: (d) => d.metal,
      },
      {
        id: "stone",
        Header: "Stone",
        accessor: (d) => d.stone,
      },
      {
        id: "quality",
        Header: "Quality",
        accessor: (d) => d.quality,
      },
      {
        id: "sku",
        Header: "SKU",
        accessor: (d) => d.sku,
      },
      {
        id: "price",
        Header: "Price",
        accessor: (d) => d.price,
      },
      {
        id: "edit",
        Header: "Edit Price",
        accessor: (d) => (
          <div className="d-flex justify-content-center">
            <Link to={`/pricing/${d._id}`}>Edit</Link>
          </div>
        ),
      },
      {
        id: "delete",
        Header: "Delete price",
        accessor: (d, index) => {
          if (this.state.confirm && d._id === this.state.id) {
            return (
              <div>
                <Copy mb={"small"}>Are you sure?</Copy>
                <div className="d-flex justify-content-between">
                  <ButtonText
                    onClick={() => {
                      this.props
                        .deletePrice({
                          variables: {
                            id: d._id,
                          },
                        })
                        .then(({ data }) => {
                          this.setState({ loading: false, sent: true });
                        })
                        .catch((error) => {
                          this.setState({
                            loading: false,
                            error: "Error updating user",
                          });
                        });
                    }}
                  >
                    Yes
                  </ButtonText>
                  <ButtonText onClick={() => this.setState({ confirm: false })}>
                    Cancel
                  </ButtonText>
                </div>
              </div>
            );
          }

          return (
            <div className="d-flex justify-content-center">
              <ButtonText
                onClick={() => this.setState({ confirm: true, id: d._id })}
              >
                Delete
              </ButtonText>
            </div>
          );
        },
      },
    ];

    return (
      <div>
        {this.state.sent ? <Alert status="success">Price Deleted</Alert> : null}
        {this.state.error ? (
          <Alert status="error">Error has occured, please try again.</Alert>
        ) : null}
        <div className="col-md-12">
          <PricingForm
            buttonLabel="Search"
            onChange={this.onChange}
            onSubmit={this.handleSubmit}
            isSearch
          />
        </div>
        <div className="col-md-12 w-100 mt-4">
          <ReactTable
            data={this.props.getPrices.prices}
            columns={columns}
            className="-striped -highlight"
            loading={this.props.getPrices.loading}
          />
        </div>
      </div>
    );
  }
}

const PRICES_QUERY = gql`
  query getPrices($input: PriceInput) {
    prices(input: $input) {
      _id
      product
      style
      shape
      metal
      stone
      quality
      sku
      price
    }
  }
`;

const DELETE_PRICE = gql`
  mutation deletePrice($id: ID) {
    deletePrice(id: $id)
  }
`;

export default compose(
  graphql(PRICES_QUERY, {
    name: "getPrices",
    options: () => {
      return {
        variables: {
          product: "CYOR",
        },
        fetchPolicy: "cache-and-network",
      };
    },
  }),
  graphql(DELETE_PRICE, {
    name: "deletePrice",
    options: {
      refetchQueries: ["getPrices"],
    },
  })
)(ArchivePriceContainer);
