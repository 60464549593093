import React, { Component } from "react";
import { graphql } from "react-apollo";
import { UserForm } from "components";
import gql from "graphql-tag";

class CreateUsersContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {}

  onChange = (n, v) => {
    this.setState({
      [n]: v,
    });
  };

  handleSubmit = () => {
    this.props
      .registerUser({
        variables: {
          ...this.state,
          isAdmin: this.state.isAdmin ? this.state.isAdmin : false,
        },
      })
      .then(({ data }) => {
        this.props.history.replace(`/users/${data.createUser._id}`);
      })
      .catch((error) => {
        console.log("there was an error sending the query", error);
      });
  };

  render() {
    if (this.props.registerUser.loading) {
      return <p>Loading</p>;
    }

    if (this.props.registerUser.error) {
      return <div>An unexpected error occurred</div>;
    }

    return (
      <div className="row">
        <div className="col-md-8">
          <UserForm
            form={this.state}
            onChange={this.onChange}
            onSubmit={this.handleSubmit}
            {...this.props}
          />
        </div>
      </div>
    );
  }
}

const registerUserMutation = gql`
  mutation createUser(
    $username: String!
    $password: String!
    $confirm: String!
    $isAdmin: Boolean
    $isPricingAdmin: Boolean
  ) {
    createUser(
      username: $username
      password: $password
      confirm: $confirm
      isAdmin: $isAdmin
      isPricingAdmin: $isPricingAdmin
    ) {
      _id
    }
  }
`;

export default graphql(registerUserMutation, { name: "registerUser" })(
  CreateUsersContainer
);
