import React, { Component } from "react";
import { graphql } from "react-apollo";
import { UserForm } from "components";
import gql from "graphql-tag";
import { pick } from "lodash";
import { compose } from "recompose";

import { Alert, Button } from "@lucio-erasmus/tfgj-components";

class EditUserContainer extends Component {
  constructor(props) {
    super(props);
    const {
      data: { page },
    } = this.props;
    this.state = {
      form: {},
      ...page,
      sent: false,
      delete: false,
    };
  }
  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.data.user !== this.props.data.user) {
      this.setState({
        form: {
          ...nextProps.data.user,
        },
      });
    }
  }

  onChange = (n, v) => {
    this.setState({
      form: {
        ...this.state.form,
        [n]: v,
      },
    });
  };

  handleSubmit = () => {
    this.setState({ sent: false, loading: true, error: null });

    this.props
      .updateUser({
        variables: {
          id: this.props.match.params.userId,
          input: {
            ...pick(this.state.form, [
              "username",
              "password",
              "isPricingAdmin",
            ]),
          },
        },
      })
      .then(({ data }) => {
        this.setState({ loading: false, sent: true });
      })
      .catch((error) => {
        this.setState({ loading: false, error: "Error updating user" });
      });
  };

  deleteUser = (id) => {
    this.setState({ loading: true });
    this.props
      .deleteUser({ variables: { id } })
      .then(({ data }) => {
        this.setState({ loading: false, delete: true });
      })
      .catch((error) => {
        console.log("there was an error sending the query", error);
        this.setState({ loading: false, error: "Error updating asset" });
      });
  };

  render() {
    if (this.props.data.loading) {
      return <p>Loading</p>;
    }

    if (this.props.data.error) {
      return <div>An unexpected error occurred</div>;
    }

    const {
      data: { user },
    } = this.props;

    if (this.state.delete)
      return <div className="alert alert-warning">User Deleted</div>;

    return (
      <div className="row">
        <div className="col-md-7">
          {this.state.sent ? (
            <Alert status="success">User Updated</Alert>
          ) : null}
          {this.state.error ? (
            <Alert status="error">Error has occured, please try again.</Alert>
          ) : null}
          <UserForm
            form={this.state.form}
            onChange={this.onChange}
            onSubmit={this.handleSubmit}
            {...this.props}
          />
        </div>
        <div className="col-md-4">
          <div className="mt-4">
            <Button onClick={() => this.deleteUser(user._id)}>
              Delete User
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const userQuery = gql`
  query userQuery($id: ID!) {
    user(id: $id) {
      _id
      username
      isPricingAdmin
    }
  }
`;

const updateUserMutation = gql`
  mutation updateUser($input: UserQuery, $id: ID!) {
    updateUser(id: $id, input: $input) {
      _id
      username
    }
  }
`;

const deleteUserMutation = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
`;

export default compose(
  graphql(userQuery, {
    options: (ownProps) => {
      return {
        variables: {
          id: ownProps.match.params.userId,
        },
      };
    },
  }),
  graphql(updateUserMutation, {
    name: "updateUser",
  }),
  graphql(deleteUserMutation, {
    name: "deleteUser",
  })
)(EditUserContainer);
