import React, { Component } from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { Link } from "react-router-dom";

class ArchiveUsersContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onRefreshClicked() {
    this.props.userQuery.refetch({
      query: this.state,
    });
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.userQuery.variables !== this.props.userQuery.variables) {
      this.setState({
        ...nextProps.userQuery.variables,
      });
    }
  }

  render() {
    const {
      userQuery: { loading, error, users },
    } = this.props;

    if (error) {
      error.graphQLErrors.forEach(({ message }, i) => {
        if (message === "Unauthorized action.") {
          this.props.history.push("/login");
        }
      });
      return <div>{error.message}</div>;
    }

    return (
      <div className="row">
        <div className="col-md-7">
          <table className="table">
            <thead>
              <tr>
                <th>User Name</th>
                <th>Options</th>
              </tr>
            </thead>

            {loading ? (
              <tbody>
                <tr>
                  <td colSpan="5">Loading</td>
                </tr>
              </tbody>
            ) : (
              <Items users={users} />
            )}
          </table>
        </div>
        <div className="col-md-3 offset-md-1">
          <div className="form-group">
            <label>Search</label>
            <input
              type="text"
              className="form-control"
              name="keyword"
              placeholder="Enter username"
              value={this.state.keyword || ""}
              onBlur={() => this.onRefreshClicked()}
              onChange={e => this.setState({ [e.target.name]: e.target.value })}
            />
          </div>
        </div>
      </div>
    );
  }
}

const Items = ({ users }) => {
  if (users.length === 0) {
    return (
      <tr>
        <td colSpan="5">No users found</td>
      </tr>
    );
  }

  return (
    <tbody>
      {users.map(user => (
        <tr key={user._id}>
          <td>{user.username}</td>
          <td>
            <Link to={`/users/${user._id}`}>View</Link>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const USERS_QUERY = gql`
  query getUsers($query: UserQuery, $offset: Int, $limit: Int) {
    users(query: $query, offset: $offset, limit: $limit) {
      _id
      username
    }
  }
`;

export default graphql(USERS_QUERY, { name: "userQuery" })(
  ArchiveUsersContainer
);
