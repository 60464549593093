import React from "react";
import { PricingForm } from "components";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { Alert } from "@lucio-erasmus/tfgj-components";
import { compose } from "recompose";

class EditPriceContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      input: {},
      sent: false,
    };
  }

  onChange = (n, v) => {
    this.setState({
      input: {
        ...this.state.input,
        [n]: v,
      },
    });
  };

  handleSubmit = (e) => {
    this.setState({ sent: false, loading: true, error: null });
    const { input } = this.state;

    const {
      getPrice: { price },
    } = this.props;

    if (!Object.keys(input).length) {
      this.setState({ loading: false, sent: true });
    } else {
      const checkIfChanged = Object.keys(input).map((key) => {
        const newInput = input[key];
        const oldInput = price[key];
        if (newInput === oldInput) {
          return true;
        } else {
          return false;
        }
      });

      if (checkIfChanged.includes(false)) {
        this.props
          .updatePrice({
            variables: {
              id: this.props.match.params.priceId,
              input: this.state.input,
            },
          })
          .then(({ data }) => {
            this.setState({ loading: false, sent: true });
          })
          .catch((error) => {
            this.setState({ loading: false, error: error.message });
          });
      } else {
        this.setState({ loading: false, sent: true });
      }
    }
  };

  render() {
    if (this.props.getPrice.loading) {
      return <p>Loading</p>;
    }

    const {
      getPrice: { price },
    } = this.props;

    return (
      <div className="col-md-7">
        <PricingForm
          form={price}
          onChange={this.onChange}
          onSubmit={this.handleSubmit}
        />
        <div className="mt-3">
          {this.state.sent && <Alert status="success">Price created</Alert>}
          {this.state.error && (
            <span className="text-danger">{this.state.error}</span>
          )}
        </div>
      </div>
    );
  }
}

const PRICE_QUERY = gql`
  query getPrice($id: ID) {
    price(id: $id) {
      _id
      product
      style
      shape
      metal
      stone
      quality
      sku
      price
    }
  }
`;

const UPDATE_PRICE = gql`
  mutation updatePrice($id: ID, $input: PriceInput) {
    updatePrice(id: $id, input: $input) {
      _id
      product
      style
      shape
      metal
      stone
      quality
      sku
      price
    }
  }
`;

export default compose(
  graphql(PRICE_QUERY, {
    name: "getPrice",
    options: (ownProps) => {
      return {
        variables: {
          id: ownProps.match.params.priceId,
        },
      };
    },
  }),
  graphql(UPDATE_PRICE, {
    name: "updatePrice",
  })
)(EditPriceContainer);
