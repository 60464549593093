import React from "react";
import { Button } from "@lucio-erasmus/tfgj-components";

const UserForm = (props) => {
  const { form, onChange, onSubmit } = props;

  return (
    <form>
      <div className="form-group">
        <label>Username</label>
        <input
          type="text"
          name="username"
          onChange={(e) => onChange(e.target.name, e.target.value)}
          className="form-control"
          value={form.username || ""}
        />
      </div>
      <div className="form-group">
        <label>Password</label>
        <input
          type="password"
          name="password"
          onChange={(e) => onChange(e.target.name, e.target.value)}
          className="form-control"
          value={form.password || ""}
        />
      </div>
      <div className="form-group">
        <label>Confirm Password</label>
        <input
          type="password"
          name="confirm"
          onChange={(e) => onChange(e.target.name, e.target.value)}
          className="form-control"
          value={form.confirm || ""}
        />
      </div>

      <div class="form-group form-check">
        <input
          type="checkbox"
          class="form-check-input"
          id="pricing-admin"
          name="isPricingAdmin"
          onChange={(e) => onChange(e.target.name, e.target.checked)}
          checked={form.isPricingAdmin || false}
        />
        <label class="form-check-label" for="exampleCheck1">
          Pricing Admin
        </label>
      </div>

      <Button
        className="btn btn-success"
        type="button"
        onClick={() => onSubmit()}
      >
        Save User
      </Button>
    </form>
  );
};

export default UserForm;
