import React from "react";
import { Button } from "@lucio-erasmus/tfgj-components";

const style = [
  { value: "", label: "Choose range..." },
  { value: "ICONIC", label: "Iconic" },
  { value: "PROMISE", label: "Promise" },
  { value: "CONSTELLATION", label: "Constellation" },
  { value: "RADIANTSTAR", label: "Radiant Star" },
  { value: "ETERNALPROMISE", label: "Emerald promise" },
  { value: "ETERNALPROMISEHALO", label: "Emerald Promise Halo" },
  { value: "SIGNATURE07", label: "Signature 7" },
  { value: "SIGNATURE08", label: "Signature 8" },
  { value: "SIGNATURE10", label: "Signature 10" },
  { value: "SIGNATURE11", label: "Signature 11" },
];

const shape = [
  { value: "", label: "Choose shape..." },
  { value: "ROUND", label: "Round" },
  { value: "PRINCESS", label: "Princess" },
  { value: "EMERALD", label: "Emerald" },
  { value: "CUSHION", label: "Cushion" },
];

const stone = [
  { value: "", label: "Choose size..." },
  { value: "0.25", label: "0.25CT" },
  { value: "0.5", label: "0.5CT" },
  { value: "0.75", label: "0.75CT" },
  { value: "1", label: "1CT" },
];

const quality = [
  { value: "", label: "Choose quality..." },
  { value: "GOOD", label: "Good" },
  { value: "BETTER", label: "Better" },
  { value: "BEST", label: "Best" },
];

const metal = [
  { value: "", label: "Choose metal..." },
  { value: "9CTWG", label: "White gold 9ct" },
  { value: "18CTWG", label: "White gold 18ct" },
  { value: "9CTYG", label: "Yellow gold 9ct" },
  { value: "18CTYG", label: "Yellow gold 18ct" },
  { value: "9CTRG", label: "Rose gold 9ct" },
  { value: "18CTRG", label: "Rose gold 18ct" },
  { value: "PT", label: "Platinum" },
];

export const pricingFields = (form, isSearch) => [
  {
    label: "Style",
    type: "select",
    name: "style",
    options: style,
    defaultOption: "Choose style...",
    defaultValue: form && form.style ? form.style : "",
    required: true,
    disabled: isSearch ? false : true,
  },
  {
    label: "Shape",
    type: "select",
    name: "shape",
    options: shape,
    defaultOption: "Choose shape...",
    defaultValue: form && form.shape ? form.shape : "",
    required: true,
    disabled: isSearch ? false : true,
  },
  {
    label: "Stone",
    type: "select",
    name: "stone",
    options: stone,
    defaultOption: "Choose stone...",
    defaultValue: form && form.stone ? form.stone : "",
    required: true,
    disabled: isSearch ? false : true,
  },
  {
    label: "Metal",
    type: "select",
    name: "metal",
    options: metal,
    defaultOption: "Choose metal...",
    defaultValue: form && form.metal ? form.metal : "",
    required: true,
    disabled: isSearch ? false : true,
  },

  {
    label: "Quality",
    type: "select",
    name: "quality",
    options: quality,
    defaultOption: "Choose quality..",
    defaultValue: form && form.quality ? form.quality : "",
    required: true,
    disabled: isSearch ? false : true,
  },
  {
    label: "Price",
    type: "text",
    name: "price",
    required: true,
    defaultValue: form && form.price ? form.price : "",
  },
];

export const PricingForm = (props) => {
  const { form, onChange, onSubmit, errors, buttonLabel, isSearch } = props;

  return (
    <form>
      <div>
        {pricingFields(form, isSearch).map((f) => {
          if (f.type === "select") {
            return (
              <div>
                <div className="form-group">
                  <label>{f.label}</label>
                  <select
                    className="form-control"
                    onChange={(e) => onChange(e.target.name, e.target.value)}
                    name={f.name}
                    defaultValue={f.defaultValue}
                    disabled={f.disabled}
                  >
                    {f.options.map((o) => (
                      <option value={o.value}>{o.label}</option>
                    ))}
                  </select>
                  <span className="text-danger" style={{ fontSize: "10px" }}>
                    {errors && errors[f.name] ? errors[f.name] : null}
                  </span>
                </div>
              </div>
            );
          }
          return (
            <div className="form-group">
              <label>{f.label}</label>
              <input
                onChange={(e) => onChange(e.target.name, e.target.value)}
                type={f.type}
                name={f.name}
                className="form-control"
                defaultValue={f.defaultValue}
                disabled={f.disabled}
              />
              <span className="text-danger" style={{ fontSize: "10px" }}>
                {errors && errors[f.name] ? errors[f.name] : null}
              </span>
            </div>
          );
        })}
      </div>
      <Button
        className="btn btn-success"
        type="button"
        onClick={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        {buttonLabel ? buttonLabel : "Save Price"}
      </Button>
    </form>
  );
};
