import React from "react";
import ReactDOMServer from "react-dom/server";

const Wistia = props => {
  let className = `wistia_embed wistia_async_${props.id}`;

  if (props.popover) {
    className += ` popover=true popoverContent=html`;
  }

  const html = ReactDOMServer.renderToStaticMarkup(
    <div
      className="wistia_responsive_wrapper"
      style={{
        height: "100%",
        left: 0,
        position: "absolute",
        top: 0,
        width: "100%"
      }}
    >
      <div
        className={`${className} videoFoam=true controlsVisibleOnLoad=true`}
        style={{ height: "100%", width: "100%" }}
      >
        &nbsp;
      </div>
    </div>
  );

  return (
    <div
      className="wistia_responsive_padding"
      style={{ padding: "56.25% 0 0 0", position: "relative" }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

Wistia.defaultProps = {
  linkLabel: "Play video"
};

export default Wistia;
