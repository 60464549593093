import React, { useState } from "react";

import { formatDate } from "../helpers/format";
import { Button } from "@lucio-erasmus/tfgj-components";
import "react-table/react-table.css";

import saveFile from "save-as-file";

const DownloadMemiCsv = ({ query }) => {
  const [downloading, setDownloading] = useState(false);
  const downloadMimeCsv = () => {
    setDownloading(true);

    const url = process.env.REACT_APP_BASE_URL
      ? `${process.env.REACT_APP_BASE_URL}/memiCsv`
      : `/memiCsv`;

    const token = localStorage.getItem("token");
    const site = localStorage.getItem("siteId");

    const {
      status,
      payment_method,
      shipping_method,
      startDate,
      endDate,
    } = query;

    var raw = JSON.stringify({
      store: site ? site : "ASJ",
      status,
      payment_method,
      shipping_method,
      startDate,
      endDate,
    });

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token ? `${token}` : "",
        siteid: site ? site : "ASJ",
      },
      body: raw,
      redirect: "follow",
    };

    const date = formatDate(new Date());

    fetch(url, requestOptions)
      .then((response) => response.blob())
      .then((result) => {
        if (result) {
          setDownloading(false);
        }
        saveFile(result, `memi-${site}-${date}.csv`);
      })
      .catch((error) => {
        setDownloading(false);
        alert(error);
      });
  };

  return (
    <Button className="btn btn-primary btn-block" onClick={downloadMimeCsv}>
      {downloading ? "Downloading..." : "Download Memi CSV"}
    </Button>
  );
};

export default DownloadMemiCsv;
