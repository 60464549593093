import React from "react";
import { Redirect } from "react-router-dom";

const AuthenticationContainer = Component => {
  class AuthenticatedComponent extends React.Component {
    checkAuth = () => {
      const token = localStorage.getItem("token");
      if (!token) {
        return false;
      }

      return true;
    };

    render() {
      return this.checkAuth() ? (
        <Component {...this.props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: this.props.location }
          }}
        />
      );
    }
  }

  return AuthenticatedComponent;
};

export default AuthenticationContainer;
