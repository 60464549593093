import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
//import registerServiceWorker from './registerServiceWorker';
import ApolloClient from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { InMemoryCache, defaultDataIdFromObject } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";

const httpLink = createHttpLink({ uri: process.env.REACT_APP_API_URL });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  const site = localStorage.getItem("siteId");

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "x-access-token": token ? `${token}` : "",
      siteid: site ? site : "ASJ",
    },
  };
});

const link = authLink.concat(httpLink);

const cache = new InMemoryCache({
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case "ConfigurationProduct":
        return object.configId; // use the `key` field as the identifier
      default:
        return defaultDataIdFromObject(object); // fall back to default handling
    }
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const tokenError = graphQLErrors.find(
      ({ message }) => message === "Unauthorized action."
    );

    if (tokenError) {
      localStorage.removeItem("token");
      window.location.replace("/login");
    }
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, link]),
  cache,
  errorLink,
});

const target = document.querySelector("#root");

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  target
);
//registerServiceWorker();
