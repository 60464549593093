import React, { Component } from "react";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import { Link } from "react-router-dom";
import { formatDate, currencyFormat } from "../../helpers/format";
import {
  Button,
  ProductRow,
  FormHeading,
  Border,
  Copy,
} from "@lucio-erasmus/tfgj-components";
import "react-table/react-table.css";
import { formatPrice } from "../../helpers/format";
import { compose } from "recompose";
import saveFile from "save-as-file";
import DownloadMemiCsv from "../../components/DownloadMemiCsv";

import ReactTable from "react-table";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { NAME_PARTS_FRAGMENT } from "./EditOrderContainer";
import "react-table/react-table.css";
import { getProductConfig, getConfigurationOptions } from "../../helpers/utils";
const EntryTotal = (props) => {
  const { onSale, salesPrice, itemPrice } = props.total;

  const itemPriceText = formatPrice(itemPrice);
  if (onSale) {
    return (
      <div>
        <div>
          <strike>R{itemPriceText}</strike>
        </div>{" "}
        R{formatPrice(salesPrice)}
      </div>
    );
  }

  return <div>R{itemPriceText}</div>;
};

class ArchivePageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setStartDate = (date) => {
    this.setState({
      startDate: date,
    });
  };

  setEndDate = (date) => {
    this.setState({
      endDate: date,
    });
  };

  onRefreshClicked = () => {
    const site = localStorage.getItem("siteId");
    this.props.ordersQuery.refetch({
      query: {
        ...this.state,
        store: site ? site : "ASJ",
      },
    });
  };

  fetchData = (state, instance) => {
    const site = localStorage.getItem("siteId");
    this.props.ordersQuery.refetch({
      query: {
        ...this.state,
        store: site ? site : "ASJ",
      },
      limit: state.pageSize,
      pageNumber: state.page + 1,
    });
  };

  getImage(e, url, name) {
    e.preventDefault();
    fetch(url)
      // 1. Convert the data into 'blob'
      .then((response) => response.blob())
      .then((blob) => {
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", `${name}`);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        alert(error);
      });
  }

  downloadCsv() {
    const url = process.env.REACT_APP_BASE_URL
      ? `${process.env.REACT_APP_BASE_URL}/csv`
      : `/csv`;

    const token = localStorage.getItem("token");
    const site = localStorage.getItem("siteId");

    const {
      status,
      payment_method,
      shipping_method,
      startDate,
      endDate,
    } = this.state;

    var raw = JSON.stringify({
      store: site ? site : "ASJ",
      status,
      payment_method,
      shipping_method,
      startDate,
      endDate,
    });

    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token ? `${token}` : "",
        siteid: site ? site : "ASJ",
      },
      body: raw,
      redirect: "follow",
    };

    const date = formatDate(new Date());

    fetch(url, requestOptions)
      .then((response) => response.blob())
      .then((result) => saveFile(result, `orders-${site}-${date}.csv`))
      .catch((error) => alert(error));
  }

  render() {
    const {
      ordersQuery: { loading, error, orders, ordersPages = 1 },
    } = this.props;

    if (error) {
      return <div>{error.message}</div>;
    }

    const columns = [
      {
        Header: "Order #",
        accessor: "orderNumber",
        width: 80,
      },
      {
        Header: "Site",
        accessor: "site",
        width: 80,
      },
      {
        Header: "Status",
        accessor: "status",
        width: 80,
      },
      {
        id: "items",
        Header: "Items",
        accessor: (d) => {
          const categories = [];
          d.lineitems.forEach((p) => {
            if (p.product.categories && p.product.categories.length) {
              p.product.categories.forEach((c) => categories.push(c.name));
            }
          });
          return categories.map((b, index) => (
            <div key={`${index}-category`}>
              <small key={index}>{b}</small>
              <br />
            </div>
          ));
        },
      },
      {
        id: "totals",
        Header: "Total",
        accessor: (d) => currencyFormat(d.totals),
      },
      {
        id: "nameSurname",
        Header: "Customer name",
        accessor: (d) => `${d.details.name} ${d.details.surname}`,
      },
      {
        Header: "Customer email",
        accessor: "details.email",
      },
      {
        Header: "Store",
        accessor: "store.name",
      },
      {
        id: "date",
        Header: "Date",
        accessor: (d) => formatDate(d.formatDate),
      },
      {
        id: "payment",
        Header: "Payment",
        accessor: "payment",
      },
      {
        id: "shipping_method",
        Header: "Shipping",
        accessor: "shipping_method.description",
      },
      {
        id: "options",
        Header: "Options",
        accessor: (d) => <Link to={`/orders/${d._id}`}>View</Link>,
      },
    ];

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="form-group col-md-6">
              <label>Search</label>
              <input
                type="text"
                className="form-control"
                name="keyword"
                placeholder="Enter search customer name / email / orderNumber"
                value={this.state.keyword || ""}
                onChange={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
              />
            </div>
            <div className="form-group col-md-6">
              <label>Status</label>
              <select
                className="form-control"
                name="status"
                onChange={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
              >
                <option />
                <option value="approved">Approved</option>
                <option value="cancel">Cancelled</option>
                <option value="pending">Pending</option>
                <option value="partial">Partial</option>
                <option value="paid">Paid</option>
                <option value="error">Error</option>
                <option value="awaitingPayment">Awaiting payment</option>
              </select>
            </div>
            <div className="form-group col-md-6">
              <label>Payment method</label>
              <select
                className="form-control"
                name="payment_method"
                onChange={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
              >
                <option />
                <option value="PAYU">PayU: Credit Card</option>
                <option value="SNAPSCAN">Snapscan</option>
                <option value="INSTORE50">Pay 50% deposit in store</option>
                <option value="EFT50">Pay 50% deposit via EFT</option>
                <option value="EFT100">Pay 100% deposit via EFT</option>
              </select>
            </div>
            <div className="form-group col-md-6">
              <label>Shipping method</label>
              <select
                className="form-control"
                name="shipping_method"
                onChange={(e) =>
                  this.setState({ [e.target.name]: e.target.value })
                }
              >
                <option />
                <option value="collection">Click and Collect</option>
                <option value="free">Free</option>
                <option value="standard">Standard</option>
              </select>
            </div>
            <div className="form-group col-md-6">
              <div className="row">
                <div className="form-group col-md-3">
                  <label>Start Date</label>
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={(date) => this.setStartDate(date)}
                    selectsStart
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label>End Date</label>
                  <DatePicker
                    selected={this.state.endDate}
                    onChange={(date) => this.setEndDate(date)}
                    selectsEnd
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    minDate={this.state.startDate}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 mb-3">
                  <Button
                    className="btn btn-primary btn-block"
                    onClick={this.onRefreshClicked}
                  >
                    Search
                  </Button>
                </div>
                <div className="col-md-3 mb-3">
                  <Button
                    className="btn btn-primary btn-block"
                    onClick={() => {
                      this.downloadCsv();
                    }}
                  >
                    Export orders
                  </Button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 mb-3">
                  <DownloadMemiCsv query={this.state} />
                </div>
              </div>
            </div>

            {/* <div className="form-group col-md-4">
              <label>Store</label>
              <select
                className="form-control"
                name="store"
                onChange={e => this.setState({ store: e.target.value })}
              >
                <option value={null} selected>
                  All
                </option>
                <option value="ASJ">ASJ</option>
                <option value="Sterns">Sterns</option>
              </select>
            </div> */}
          </div>
          <div className="row"></div>
        </div>
        <div className="col-md-12 w-100">
          <ReactTable
            data={orders}
            pages={ordersPages}
            columns={columns}
            loading={loading}
            manual
            onFetchData={this.fetchData}
            SubComponent={(d) => {
              return d.original.lineitems.map((entry, index) => {
                const product = getProductConfig(
                  entry.configuration,
                  getConfigurationOptions(
                    entry.product.configuration.components
                  )
                );
                return (
                  <div key={`${entry.id}-${index}`}>
                    <ProductRow
                      title={entry.product.name}
                      total={<EntryTotal total={entry.total} />}
                      renderMeta={() => {
                        return (
                          <div>
                            {product
                              .filter((c) => c.sku !== "UPLOAD-IMG")
                              .map((c, index) => {
                                return (
                                  <Copy size="small" mb={"none"}>
                                    {c.group}: {c.value || c.name}
                                    {c.font ? ` - ${c.font}` : null}{" "}
                                    {c.languageLabel
                                      ? ` - ${c.languageLabel}`
                                      : null}{" "}
                                  </Copy>
                                );
                              })}
                            <a
                              href={entry.imageUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View image
                            </a>
                            <FormHeading>Downloads</FormHeading>
                            <Border color="white" mt="small" mb="small" />

                            <div>
                              {entry.images && entry.images
                                ? entry.images.map((i) => (
                                    <div>
                                      <a
                                        href={`${process.env.REACT_APP_PRODUCT}${i._id}`}
                                        onClick={(e) =>
                                          this.getImage(
                                            e,
                                            `${process.env.REACT_APP_PRODUCT}${i._id}`,
                                            i.filename
                                          )
                                        }
                                      >
                                        {`Download ${i.filename}`}
                                      </a>
                                      <br />
                                    </div>
                                  ))
                                : null}
                              <br />
                            </div>
                          </div>
                        );
                      }}
                      image={entry.imageUrl}
                    />
                  </div>
                );
              });
            }}
            className="-striped -highlight"
          />
        </div>
      </div>
    );
  }
}

const FEED_QUERY = gql`
  ${NAME_PARTS_FRAGMENT}
  query getOrders($query: OrderQuery, $pageNumber: Int, $limit: Int) {
    ordersPages(query: $query, limit: $limit)
    orders(query: $query, pageNumber: $pageNumber, limit: $limit) {
      _id
      totals
      status
      site
      orderNumber
      details {
        name
        surname
        email
        phone
      }
      store {
        name
        address
        email
        branch
      }
      payment
      formatDate
      status
      due
      shipping_method {
        description
      }
      lineitems {
        id
        productId
        imageUrl
        images {
          filename
          _id
        }
        product {
          name
          categories {
            name
          }
          configuration {
            id
            components {
              ...NameParts
              options {
                ...NameParts
                styles {
                  ...NameParts
                }
                items {
                  name
                  group
                  image
                  description
                  assetImage
                  id
                  sku
                  parentSku
                  price
                  priceCategory
                  exclusions {
                    key
                    values
                  }
                  styles {
                    ...NameParts
                  }
                }
              }
              styles {
                ...NameParts
              }
              items {
                name
                group
                image
                description
                assetImage
                id
                sku
                parentSku
                price
                priceCategory
                exclusions {
                  key
                  values
                }
                styles {
                  ...NameParts
                }
              }
            }
          }
        }
        configuration {
          configId
          sku
          price
          id
          image
          type
          font
          language
          priceCategory
          specification
          exclusions {
            key
            values
          }
          description
          assetImage
          group
          name
          value
        }
        total: orderTotal {
          discount
          itemPrice
          onSale
          salesPrice
        }
      }
    }
  }
`;

export default compose(
  graphql(FEED_QUERY, {
    name: "ordersQuery",
    options: (ownProps) => {
      const site = localStorage.getItem("siteId");

      return {
        variables: {
          query: {
            store: site ? site : "ASJ",
          },
        },
      };
    },
  })
)(ArchivePageContainer);
