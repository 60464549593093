import React from "react";
import TagsInput from "react-tagsinput";
import Autosuggest from "react-autosuggest";
import "react-tagsinput/react-tagsinput.css";

class AutocompleteExample extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tags: this.props.values };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.values !== this.props.values) {
      this.setState({
        tags: nextProps.values
      });
    }
  }

  handleChange = tags => {
    this.props.onChange(this.props.name, tags);
  };

  render() {
    const { options } = this.props;
    function autocompleteRenderInput(args) {
      const { addTag, ...props } = args;
      const handleOnChange = (e, { newValue, method }) => {
        if (method === "enter") {
          e.preventDefault();
        } else {
          props.onChange(e);
        }
      };

      const inputValue =
        (props.value && props.value.trim().toLowerCase()) || "";
      const inputLength = inputValue.length;

      let suggestions = options.filter(state => {
        return state.name.toLowerCase().slice(0, inputLength) === inputValue;
      });

      return (
        <Autosuggest
          ref={props.ref}
          suggestions={suggestions}
          shouldRenderSuggestions={value => value && value.trim().length > 0}
          getSuggestionValue={suggestion => suggestion.name}
          renderSuggestion={suggestion => <span>{suggestion.name}</span>}
          inputProps={{ ...props, onChange: handleOnChange }}
          onSuggestionSelected={(e, { suggestion }) => {
            addTag(suggestion.name);
          }}
          onSuggestionsClearRequested={() => {}}
          onSuggestionsFetchRequested={() => {}}
        />
      );
    }

    return (
      <TagsInput
        renderInput={autocompleteRenderInput}
        value={this.state.tags}
        onChange={this.handleChange}
      />
    );
  }
}

export default AutocompleteExample;
